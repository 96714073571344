import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    calloutHandler,
    descriptionHandler,
    headlineHandler,
    longHeadlineHandler,
    imgHandler,
    logoHandler
} from "./addAssetsGroup/addAssetsUtility/inputConfigData";
import AddImages from "../addImages/AddImages";
import BrandName from "./addAssetsGroup/BrandName";
import Headlines from "./addAssetsGroup/Headlines";
import LongHeadlines from "./addAssetsGroup/LongHeadlines";
import Descriptions from "./addAssetsGroup/Description";
import Callouts from "./addAssetsGroup/Callouts";
import {useSiteId} from "../../../app/globalHooks";
import {
    selectAssetsLoaded,
    selectAssetScope,
    selectProductAssets,
    selectProductAssetSelected,
    selectProductInfoSelected,
    actionSetLogo,
    actionRemoveLogo,
    actionRemoveImage,
    actionSetImages,
} from "../reducers/createNewCampaignSlice";
import CustomizableSkeleton from "../../../components/skeleton/CustomizableSkeleton";
import {AzaGrid, AzaGridItem} from "../../../components/mui/AzaGrid";
import AzaCardTemplate from "../../../components/azameo/AzaCardTemplate";
import SelectProduct from "./addAssetsGroup/selectProduct/SelectProduct";
import AdAssetToolbar from "./addAssetsGroup/addAssetsUtility/AdAssetToolbar";
import {useFileStoreProvider} from "../../../features/fileStoreContext";

const AdAssets = () => {
    const {t} = useTranslation();
    const site_id = useSiteId();
    const dispatch = useDispatch();
    const isLoaded = useSelector(selectAssetsLoaded);
    const assetsScope = useSelector(selectAssetScope);
    const productWithAssets = useSelector(selectProductAssets);
    const assetsDataProduct = useSelector(selectProductAssetSelected);
    const productId = useSelector(selectProductInfoSelected).prodId;
    const minHeadlines = headlineHandler.minInputs;
    const maxHeadlines = headlineHandler.maxInputs;
    const minLongHeadlines = longHeadlineHandler.minInputs;
    const maxLongHeadlines = longHeadlineHandler.maxInputs;
    const minDescriptions = descriptionHandler.minInputs;
    const maxDescriptions = descriptionHandler.maxInputs;
    const minCallouts = calloutHandler.minInputs;
    const maxCallouts = calloutHandler.maxInputs;
    const minImg = imgHandler.minInputs;
    const maxImg = imgHandler.maxInputs;
    const productIdsWithAssets = Object.keys(productWithAssets);
    const {addFile, removeFile, allFiles} = useFileStoreProvider();
    const logo = allFiles('logo');
    const images = allFiles('images');

    const setLogo = (files) => {
        files.map(e => addFile('logo', e.name, e))
        dispatch(actionSetLogo({data: files.map(e => e.name)}))
    }

    const delLogo = (file) => {
        removeFile('logo', file.name);
        dispatch(actionRemoveLogo(file.name))
    }

    const setImages = (files) => {
        files.map(e => addFile('images', e.name, e))
        dispatch(actionSetImages({data: files.map(e => e.name)}))
    }
    const delImages = (file) => {
        removeFile('images', file.name);
        dispatch(actionRemoveImage(file.name))
    }

    return isLoaded ? (
        <AzaCardTemplate title={''} component={AdAssetToolbar}>
            {assetsScope.scopeName === 'Site' && <BrandName
                inputGroupName={t('brand-name')}
                inputPlaceholder={t('brand-name')}
            />}
            {assetsScope.scopeName === 'Product' && (
                <SelectProduct
                    productIdsWithAssets={productIdsWithAssets}
                    productWithAssets={productWithAssets}
                    scope={assetsScope.scopeName}
                    siteId={site_id}
                />
            )}

            <Headlines
                inputGroupName={t('headline', {count: 2})}
                addNewInputBtnName={t('headline', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={headlineHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                // is it ok this realisation?
                minMaxIndication={t('add-assets', {
                    from: minHeadlines, to: maxHeadlines, val: '$t(headlines)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            <LongHeadlines
                inputGroupName={t('long-headline', {count: 2})}
                addNewInputBtnName={t('long-headline', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={longHeadlineHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                minMaxIndication={t('add-assets', {
                    from: minLongHeadlines, to: maxLongHeadlines, val: '$t(long-headlines)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            <Descriptions
                inputGroupName={t('description', {count: 2})}
                addNewInputBtnName={t('description', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={descriptionHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                minMaxIndication={t('add-assets', {
                    from: minDescriptions, to: maxDescriptions, val: '$t(descriptions)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            <Callouts
                inputGroupName={t('callout', {count: 2})}
                addNewInputBtnName={t('callout', {count: 1})}
                toolTipsText={t('tooltips-headlines')}
                configData={calloutHandler}
                currentSiteId={site_id}
                assetsScope={assetsScope.scopeName}
                assetsDataProduct={assetsDataProduct}
                productId={productId}
                minMaxIndication={t('add-assets', {
                    from: minCallouts, to: maxCallouts, val: '$t(callouts)',
                    interpolation: {
                        skipOnVariables: false,
                    },
                })}
            />

            {/* NEED TO BE ADJUSTED FOR PRODUCT TAB */}
            <AzaGrid container spacing={2}>
                <AzaGridItem xs={12} md={6}>
                    <AddImages
                        inputGroupName={t('images.add-logo')}
                        configData={logoHandler}
                        minMaxIndication={t('images.add-img-logo')}
                        isLogo={true}
                        images={logo}
                        addImage={setLogo}
                        removeImage={delLogo}
                    />
                </AzaGridItem>
                {/*{assetsScope.scopeName === 'Site' && */}
                    <AzaGridItem xs={12} md={6}>
                    <AddImages
                        inputGroupName={t('images.add-image')}
                        configData={imgHandler}
                        minMaxIndication={t('add-assets', {
                            from: minImg, to: maxImg, val: '$t(images.images)',
                            interpolation: {
                                skipOnVariables: false,
                            },
                        })}
                        images={images}
                        addImage={setImages}
                        removeImage={delImages}
                    />
                </AzaGridItem>
            {/*}*/}
            </AzaGrid>
        </AzaCardTemplate>
    ) : (<>
        <CustomizableSkeleton numbCols={2} numbRows={2} />
        <CustomizableSkeleton numbCols={2} numbRows={2} />
        <CustomizableSkeleton numbCols={2} numbRows={2} />
        <CustomizableSkeleton numbCols={2} numbRows={2} />
    </>)
};

export default AdAssets;

