import {AzaBox} from "../../components/mui/AzaBox";
import EditBudget from "./editCampaignGroup/EditBudget";
import EditTitle from "./editCampaignGroup/EditTitle";

const EditCampaign = () => {
    /* not sure that we need reducer:
    on click on Link (CampaignGroup.js -> SubDataCampaign) set campaign id to API function (!not ready yet) to get data
    about chose campaign
    Probably we will need redux to put data from back, like intermediate
     */

    return (
        <AzaBox>
            <AzaBox>
                <EditTitle/>
            </AzaBox>
            <AzaBox>
                <EditBudget/>
            </AzaBox>
        </AzaBox>
    );
};

export default EditCampaign;