import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

import {useTheme} from "@mui/material";

import {useCampaignColumns} from "./utils/campaignDataHooks";
import {selectedCampaign} from "./campaignDataSlice";
import {AzaIconButton} from "../../../components/mui/AzaButton";
import AzaSettingsBox from "../../../components/azameo/AzaSettingsBox";
import {AzaSwitch} from "../../../components/mui/AzaSwitch";
import {useFormat, useScreenSize, useSiteId, useUser} from "../../../app/globalHooks";
import {descendingComparator} from "../../../utils/sortingFunctionalities";
import {methodType, siteBackendFetch} from "../../../utils/backendHelper";
import {API_POST_MODIFY, PATH_EDIT_CAMPAIGN} from "../../../utils/constant";
import {
    AzaTable,
    AzaTableBody,
    AzaTableCell,
    AzaTableCellBottom,
    AzaTableCellHead,
    AzaTableHead,
    AzaTableRow,
    AzaTableRowHead,
    AzaTableSortLabel
} from "../../../components/mui/AzaTable";
import {AzaCheckbox} from "../../../components/mui/AzaCheckbox";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaKeyboardArrowDown, AzaKeyboardArrowRight} from "../../../components/mui/AzaIcons";
import {AdminModeBranch} from "../../../features/adminMode/adminModeComponents";
import AzaLink from "../../../components/mui/AzaLink";
import {useEditCampaign} from "../../../containers/editCampaign/editCampaignUtile/editCampaignHook";

const translatableGroupName = ["managed", "Total", "deleted"];

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const get_nested_value = (dictionary, key) => {
    const keys = key.split(".");
    let value = dictionary;
    for (let k of keys) {
        if (value === undefined) {
            return undefined;
        }
        value = value[k];
    }
    return value;
}

const SubDataCampaign = (params) => {
    const {campaignData, sortedColumns, campaign, isItemSelected, handleClickSelectSingle, campaignStatus} = params;
    const [open, setOpen] = useState(false);
    const {user} = useUser();
    const site_id = useSiteId();
    const campaignId = campaign.campaign_id;
    const {requiredData, campaignColumns } = useCampaignColumns();
    const {formattedData} = useFormat();
    const {isSmallScreen} = useScreenSize();
    const {geData} = useEditCampaign();
    const theme = useTheme();

    const handleDetailsShow = () => {
        setOpen(!open);
    }

    const handleStartStopSwitch = (e) => {
        const data = {
            status: e.target.checked ? 'RESUME': 'SUSPEND'
        }
        siteBackendFetch({
            user: user,
            site_id: site_id,
            path: API_POST_MODIFY,
            queryParams: {
                campaign_id: campaignId
            },
            method: methodType.POST,
            data: {params:data},
        })
            .then(() => {})
            .catch(error => {
                alert(e.target.checked ? 'Campaign start RUNNING (backend communication error)' : 'Campaign will be STOPPED (backend communication error)');
                console.log(error);
        })
            .finally(() => {});
    }

    const dailyTableData = useMemo(() => {
        if (!open) {
            return <></>
        }
        return (
            campaignData.map((el) => {
                return(
                    <AzaTableRow
                        key={el.campaign_id + el.date}
                    >
                        {/*
                            the data comes from the fifth column (no data before)
                            date will use four columns, so we should merge them
                            need to filter the following columns so that the first four are not used
                        */}
                        <AzaTableCell colSpan={isSmallScreen?4:5}>
                            {formattedData(Date.parse(el.date), "formatDate")}
                        </AzaTableCell>
                        {sortedColumns.filter((el) => requiredData.indexOf(el) === - 1).map((column) => {
                               return (
                                    <AzaTableCell
                                        key={column}
                                    >
                                        {formattedData(el[column], campaignColumns[column].format, campaignColumns[column].params)}
                                    </AzaTableCell>
                                )
                            }
                        )}
                    </AzaTableRow>
                )
            })
        );
    }, [sortedColumns, campaignData, open, requiredData, campaignColumns, formattedData, isSmallScreen]);

    return (
        <>
            <AzaTableRow key={campaign["campaign_name"]}  className={campaignId}>
                {!isSmallScreen &&<AzaTableCell align="center" padding="checkbox" sx={{border: 'none'}}>
                    <AzaCheckbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) => handleClickSelectSingle(event,
                            {
                                campaign_id: campaignId,
                                campaign_name: campaign.campaign_name,
                                campaign_status: campaignStatus,
                            })}
                        disabled={campaign.unsupported_lever}
                    />
                </AzaTableCell>}
                {requiredData.map((column) => {
                    if(column === 'notification' && campaign[column].length > 0) {
                        return (
                            <AzaTableCell key={column}>
                                {campaign[column][0]}
                            </AzaTableCell>
                        )
                    } else if(column === 'network') {
                        return (
                            <AzaTableCell key={column} align={"center"}>
                                {formattedData(campaign[column], campaignColumns[column].format, campaignColumns[column].params)}
                            </AzaTableCell>
                        )
                    } else if(column === 'status') {
                        return (
                            <AzaTableCell key={column} sx={{width: '120px'}}>
                                <AzaBox
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    {<AzaSwitch
                                        defaultChecked={campaign[column] === 'RUNNING' || campaign[column] === "IN PREPARATION"}
                                        onChange={handleStartStopSwitch}
                                        disabled={campaignStatus === 'deleted' || campaign.unsupported_lever === true}
                                    />}
                                    <AzaIconButton
                                        color="primary"
                                        sx={{minWidth: '24px'}}
                                        onClick={handleDetailsShow}
                                    >
                                        {open ? <AzaKeyboardArrowDown/> : <AzaKeyboardArrowRight/>}
                                    </AzaIconButton>
                                </AzaBox>
                            </AzaTableCell>
                        );
                    } else if (column === 'campaign_name'){
                        return (
                            <AzaTableCell key={column} sx={{textAlign: 'left'}}>
                                <Link
                                    style={{textDecoration: 'none', color: 'inherit',}}
                                    to={PATH_EDIT_CAMPAIGN}
                                    onClick={() => geData(campaign)}
                                >
                                    <AzaBox component={'span'} sx={{'&:hover': {color: theme.palette.primary.dark}}}>
                                        {campaign[column]}
                                    </AzaBox>
                                </Link>
                            </AzaTableCell>
                        )
                    } else {
                        return (
                            <AzaTableCell key={column} sx={{textAlign: 'left'}}>
                                {campaign[column]}
                            </AzaTableCell>
                        )
                    }
                })}
                {sortedColumns.filter((el) => requiredData.indexOf(el) === - 1).map((column) => {
                    if(column === 'campaign_code') {
                       return (
                           <AdminModeBranch key={column} needAdmin={true}>
                               <AzaTableCell>
                                   <AzaLink
                                       href={"https://backend.azame.net/admin/customermetadata/campaign/?code__exact=" + campaign[column]}
                                       target="_blank"
                                       rel="noopener"
                                   >
                                       {campaign[column]}
                                   </AzaLink>
                               </AzaTableCell>
                           </AdminModeBranch>
                       )
                    } else {
                        return (
                            <AzaTableCell
                                key={column}
                            >
                                {formattedData(campaignColumns[column].key?get_nested_value(campaign[column], campaignColumns[column].key):campaign[column], campaignColumns[column].format, campaignColumns[column].params)}
                            </AzaTableCell>
                        )
                    }
                })}
            </AzaTableRow>
            {dailyTableData}
        </>
    )
}

export const CampaignGroup = ({groupData, attributionColumns, order, orderBy, requestSort}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState([]);
    const numSelected = selected.length;
    const rowCount = groupData.list_campaigns.length;
    const dispatch = useDispatch();
    const {requiredData, campaignColumns, orderColumnModel, } = useCampaignColumns();
    const {formattedData} = useFormat();
    const {isSmallScreen} = useScreenSize();

    useEffect(() => {
        dispatch(selectedCampaign(selected));
    }, [selected, dispatch]);
    // Translate only some group names
    const groupName = useMemo(() => {
        if (translatableGroupName.includes(groupData.name))
            {
                // noinspection AllyPlainJsInspection
                return t(`campaign_group.${groupData.name.toLowerCase()}`)
            }
        return groupData.name
    }, [groupData?.name, t]);

    const handleClickSelectAll = (event) => {
        if(event.target.checked) {
            const newSelected = groupData.list_campaigns.filter((campaign) =>
                campaign.unsupported_lever !== true
            )
                .map((campaign) => {
                return {
                    campaign_id: campaign.campaign_id,
                    campaign_name: campaign.campaign_name,
                    campaign_status: groupData.name,
                }
            });
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    }

    const handleClickSelectSingle = (event, element) => {
        const selectedIndex = selected.map((el) => el.campaign_id).indexOf(element.campaign_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, element);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    const isSelected = (id) => {
        return selected.map((el) => el.campaign_id).indexOf(id) !== -1;
    }
    const sortedColumns = useMemo(() => {
        return orderColumnModel.filter((el) => attributionColumns.indexOf(el) !== -1);
    }, [orderColumnModel, attributionColumns]);

    return (
        <AzaSettingsBox
            title={{
                name: groupName,
            }}
            childsx={{
                padding: '8px 0px 0px'
            }}
        >
            <AzaTable sx={{
                            width: "100%",
                            minWidth: 350
                        }}
                      size="small"
            >
                <AzaTableHead>
                    <AzaTableRowHead>
                        {!isSmallScreen &&
                            <AzaTableCellHead padding="checkbox">
                            <AzaCheckbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={handleClickSelectAll}
                                inputProps={{
                                    'aria-label': 'select all',
                                }}
                            />
                            </AzaTableCellHead>
                        }
                        {requiredData.map((column) => {
                            return (
                               <AzaTableCellHead
                                    sx={{
                                        padding: '10px 4px',
                                        maxWidth: '150px',
                                        textAlign: 'center',
                                        paddingLeft: '30px',
                                    }}
                                    key={column}
                                    sortDirection={orderBy === column ? order : false}
                                    data-column={column}
                               >
                                {column === 'status' ? campaignColumns[column]?.name :
                                    <AzaTableSortLabel
                                        active={orderBy === column}
                                        direction={orderBy === column ? order : 'asc'}
                                        onClick={(e) => requestSort(e, column)}
                                    >
                                        {campaignColumns[column]?.name}
                                    </AzaTableSortLabel>}
                                </AzaTableCellHead>
                            )}
                        )}
                        {sortedColumns.filter((el) => requiredData.indexOf(el) === - 1).map((column) => {
                           return (
                               <AdminModeBranch key={column} needAdmin={column === 'campaign_code'}>
                                   <AzaTableCellHead
                                       sx={{
                                           padding: '10px 4px',
                                           maxWidth: '150px',
                                           textAlign: 'center',
                                           paddingLeft: '30px',
                                       }}
                                       sortDirection={orderBy === column ? order : false}
                                       data-column={column}
                                   >
                                       <AzaTableSortLabel
                                           active={orderBy === column}
                                           direction={orderBy === column ? order : 'asc'}
                                           onClick={(e) => requestSort(e, column)}
                                       >
                                           {campaignColumns[column]?.name}
                                       </AzaTableSortLabel>
                                   </AzaTableCellHead>
                               </AdminModeBranch>
                           )
                        })}
                    </AzaTableRowHead>
                </AzaTableHead>
                <AzaTableBody>
                    {groupData.list_campaigns.sort(getComparator(order, orderBy)).map((campaign) => {
                        const isItemSelected = isSelected(campaign.campaign_id);
                        return (
                            <SubDataCampaign
                                key={campaign.campaign_id}
                                campaign={campaign}
                                campaignData={campaign.list_date}
                                sortedColumns={sortedColumns}
                                isItemSelected={isItemSelected}
                                campaignStatus={groupData.name}
                                handleClickSelectSingle={handleClickSelectSingle}
                            />
                        )})}
                    <AzaTableRow key={groupData.total.campaign_name}>
                        <AzaTableCellBottom colSpan={isSmallScreen?4:5}>
                            {groupData.total['campaign_name']}
                        </AzaTableCellBottom>
                        {sortedColumns.filter((el) => requiredData.indexOf(el) === - 1).map((column) => (
                            <AdminModeBranch key={column} needAdmin={column === 'campaign_code'}>
                                <AzaTableCellBottom key={column}>
                                    { column !== 'campaign_code' &&
                                        formattedData(
                                            groupData['total'][column] === 'Total' ? undefined : groupData['total'][column],
                                            campaignColumns[column].format,
                                            campaignColumns[column].params
                                        )
                                    }
                                </AzaTableCellBottom>
                            </AdminModeBranch>)
                        )}
                    </AzaTableRow>
                </AzaTableBody>
            </AzaTable>
        </AzaSettingsBox>
    )
}
