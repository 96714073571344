import {createSlice} from "@reduxjs/toolkit";

import {MIN_BUDGET_AMOUNT, MIN_TARGET_ROI} from "../../createNewCampaign/createNewCampaignUtile/createNewCamaignConst";

const editCampaignSliceName = 'editCampaignData';

const initialState = {
    campaign_id: '',
    campaign_name: '',
    budget: {
        daily_budget_data: {
            daily_budget: MIN_BUDGET_AMOUNT,
            error: {status: false, massage: ''}
        },
        roi_checked: false,
        exceed_daily_budget_data: {
            exceed_daily_budget: MIN_TARGET_ROI,
            error: {status: false, message: ''}
        }
    },
}

export const editCampaignSlice = createSlice({
    name: `${editCampaignSliceName}`,
    initialState,
    reducers: {
        actionSetCampaignId(state, action) {
            state.campaign_id = action.payload;
        },

        actionSetCampaignName(state, action) {
            state.campaign_name = action.payload;
        },

        actionSetBudget(state, action) {
            state.budget = action.payload;
        }
    }
});

const {actions} = editCampaignSlice;
export const {
    actionSetCampaignId,
    actionSetCampaignName,
    actionSetBudget,
} = actions;

export const selectCampaignId = (state) => {
    return state[`${editCampaignSliceName}`].campaign_id;
}

export const selectCampaignName = (state) => {
    return state[`${editCampaignSliceName}`].campaign_name;
}

export const selectBudget = (state) => {
    return state[`${editCampaignSliceName}`].budget;
}