import {useCallback} from "react";
import {useDispatch} from "react-redux";

import {actionSetBudget, actionSetCampaignName} from "../reducers/editCampaignSlice";
// import {getEditCampaignData} from "./getEditCampaignData";

export const useEditCampaign = () => {
    const dispatch = useDispatch();

    const setData = useCallback((data) => {
        const campaignName = data.campaign_name;
        const budget = {
            daily_budget_data: {
                daily_budget: data.budget.daily
            },
            roi_checked: true,
            exceed_daily_budget_data: {
                exceed_daily_budget: '202'
            }
        }
        dispatch(actionSetCampaignName(campaignName));
        dispatch(actionSetBudget(budget));
    }, [dispatch]);

    const geData = useCallback((data) => {
        // what we need from 'data'? 'campaign_id' ?
        // getEditCampaignData(data.campaign_id)
        setData(data);
    }, [setData]);

    return {geData, setData}
}