import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {facebookSlice} from "../widgets/facebook/facebookSlice";

import {globalSlice} from "./globalSlice";

import {attributionSlice} from "../features/attribution/attributionSlice";
import {paymentSlice} from "../containers/payment/paymentGroup/paymentSlice";
import {backendSettingSlice} from "../pages/admin/backendSettings/BackendSettingsSlice";
import {ACTION_RESET_STATE} from "../utils/constant";
import {campaignDataSlice} from "../widgets/Attribution/CampaignData/campaignDataSlice";
import {createNewCampaignSlice} from "../containers/createNewCampaign/reducers/createNewCampaignSlice";
import {editCampaignSlice} from "../containers/editCampaign/reducers/editCampaignSlice";

const appReducers = combineReducers({
        [globalSlice.name]: globalSlice.reducer,
        [facebookSlice.name]: facebookSlice.reducer,
        [paymentSlice.name]: paymentSlice.reducer,
        [attributionSlice.name]: attributionSlice.reducer,
        [backendSettingSlice.name]: backendSettingSlice.reducer,
        [campaignDataSlice.name]: campaignDataSlice.reducer,
        [createNewCampaignSlice.name]: createNewCampaignSlice.reducer,
        [editCampaignSlice.name]: editCampaignSlice.reducer,
});

const rootReducer = (state, action) => {
    if (action.type === ACTION_RESET_STATE) {
        return appReducers(undefined, action)
    }
    return appReducers(state, action)
}

export const store = configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(brandroiMiddleware),
});
