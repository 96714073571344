import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
// import {useStore} from "react-redux";

import AdAssets from "./AdAssets";
import Audience from "./Audience";
// import Networks from "../createNewCampaignGroup/Networks";
import Settings from "./Settings";
import CompletionBlock from "../createNewCampaignUtile/CompletionBlock";
import {AzaBox} from "../../../components/mui/AzaBox";
import {AzaAlert} from "../../../components/mui/AzaAlert";
import {AzaStep, AzaStepLabel, AzaStepper} from "../../../components/mui/AzaStepper";
import {AzaButton} from "../../../components/mui/AzaButton";
import {
    selectCheckerSiteId,
    selectHeadlines,
    selectAssetScope,
    selectLongHeadlines,
    selectDescriptions,
    selectCallouts,
    selectBrandName,
    actionUpdateAssets,
    selectProductAssetSelected,
    actionSetAssetsBrandName,
    selectImages,
    // actionSetImages,
    selectKeywords,
    selectCountries,
    selectLanguages,
    actionSetCountries,
    actionSetLanguages,
    selectNetworkAzameo,
    selectNetworkGoogle,
    selectNetworkMicrosoft,
    actionSetIsNetworkSelected,
    selectCampaignName,
    actionSetCampaignName,
    selectDailyBudget,
    actionSetDailyBudget,
    selectCheckedExceedBudget,
    selectExceedDailyBudget,
    actionSetExceedDailyBudget,
    actionSetErrorCounter,
    selectErrorsCounter,
    selectAllCampaignsNames,
    selectProductInfoSelected,
    selectCampaignParams,
    selectLogo,
    actionSetAssetsWasLoaded,
    selectAssetsWasLoaded,
    selectOnLoadingState,
} from "../reducers/createNewCampaignSlice";
import {selectSiteLogo, selectSiteListLoaded} from "../../../app/globalSlice";
import {useSiteId, useUser} from "../../../app/globalHooks";
import {usePayment} from "../../payment/paymentGroup/paymentHook";
import {AzaPaper} from "../../../components/mui/AzaPaper";
import {AzaGridContainer, AzaGridItem} from "../../../components/mui/AzaGrid";
import {REGEX_TEXT_CHECKER} from "../createNewCampaignUtile/createNewCamaignConst";

import {useAdminModeProvider} from "../../../features/adminMode/adminModeContext";
import {
    headlineHandler, longHeadlineHandler, descriptionHandler, calloutHandler, keywordHandler
} from "./addAssetsGroup/addAssetsUtility/inputConfigData";
import {MIN_BUDGET_AMOUNT, MIN_TARGET_ROI} from "../createNewCampaignUtile/createNewCamaignConst";
import {createCampaign} from "../../../utils/backendHelper";
import {useFileStoreProvider} from "../../../features/fileStoreContext";
// import { setGlobalSettings} from "../../../pages/admin/backendSettings/BackendSettingsSlice";
import campaignParamsEditor from "../createNewCampaignUtile/campaignParamsEditor";
import {PATH_CAMPAIGNS} from "../../../utils/constant";
import {useNavigate} from "react-router-dom";
import {
    AzaDialog,
    AzaDialogActions,
    AzaDialogContent,
    AzaDialogContentText,
    AzaDialogTitle
} from "../../../components/mui/AzaDialog";
import {useCreateNewCampaign} from "../createNewCampaignUtile/createNewCampaignHook";


export default function HorizontalCreationStepper() {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [openErrorWarning, setOpenErrorWarning] = useState(false);
    const [creating, setCreating] = useState(false);
    const {isAdmin} = useAdminModeProvider();
    const {t} = useTranslation();
    const {currency} = usePayment();
    const checkerSiteId = useSelector(selectCheckerSiteId);
    const site_id = useSiteId();
    const {user} = useUser();
    const allCampaignsNames = useSelector(selectAllCampaignsNames);
    const completion = 'completion';
    const brandName = useSelector(selectBrandName);
    const assetsScopeObj = useSelector(selectAssetScope);
    const headlinesToValidate = useSelector(selectHeadlines);
    const longHeadlinesToValidate = useSelector(selectLongHeadlines);
    const descriptionsToValidate = useSelector(selectDescriptions);
    const calloutsToValidate = useSelector(selectCallouts);
    const productAssets = useSelector(selectProductAssetSelected);
    const logoToValidate = useSelector(selectSiteLogo);
    const imgToValidate = useSelector(selectImages);
    const keywordsToValidate = useSelector(selectKeywords);
    const countriesToValidate = useSelector(selectCountries);
    const languageToValidate = useSelector(selectLanguages);
    const networkAzameo = useSelector(selectNetworkAzameo);
    const networkGoogle = useSelector(selectNetworkGoogle);
    const networkMicrosoft = useSelector(selectNetworkMicrosoft);
    const siteListLoaded = useSelector(selectSiteListLoaded);
    const campaignNameData = useSelector(selectCampaignName);
    const dailyBudgetData = useSelector(selectDailyBudget);
    const exceedBudgetChecked = useSelector(selectCheckedExceedBudget);
    const exceedBudgetData = useSelector(selectExceedDailyBudget);
    const errorsCounter = useSelector(selectErrorsCounter);
    let campaignParams = useSelector(selectCampaignParams);
    const {allFiles} = useFileStoreProvider();
    const productName = useSelector(selectProductInfoSelected).prodName;
    const logoDataFromRedux = useSelector(selectLogo);
    const navigate = useNavigate();
    const isDataLoaded = useSelector(selectAssetsWasLoaded);
    const isOnLoading = useSelector(selectOnLoadingState);
    const {resetData} = useCreateNewCampaign();

    const maxLength = useMemo(() => ({
        'Headline': headlineHandler.maxLength,
        'Long Headline': longHeadlineHandler.maxLength,
        'Description': descriptionHandler.maxLength,
        'Callout': calloutHandler.maxLength,
        'Keyword': keywordHandler.maxLength,
    }), []);

    const steps = [
        /*
        !!!! WARNING: if we add or remove one (or more) step, it impacts validation
         */
        {
            label: t('creation-steps.ad-asset'),
            stepComponent: <AdAssets/>,

            /* hardcoded */
            // recommendation: 'Add products, 1 image',
            errorMsg: 'Fix {4} error',
            progress: 70,
        },
        {
            label: t('creation-steps.audience'),
            stepComponent: <Audience/>,

            /* hardcoded */
            // recommendation: 'Add audience',
            // errorMsg: 'Fix {1} error',
            progress: 10,
        },
        // {
        //     label: t('creation-steps.networks'),
        //     stepComponent: <Networks/>,
        //
        //     /* hardcoded */
        //     // recommendation: 'Add products, 1 image',
        //     errorMsg: 'Fix {2} error',
        //     progress: 1,
        // },
        {
            label: t('creation-steps.settings'),
            stepComponent: <Settings/>,

            /* hardcoded */
            // recommendation: 'Add products, 1 image',
            // errorMsg: 'Fix {1} error',
            progress: 32,
        },
    ];

    const handleCloseErrorWarning = useCallback(() => {
        setOpenErrorWarning(false);
    }, []);

    const assetsValidationHelper = useCallback((dataToValidate, regExValidation) => {
        let errorFound = false;
        let countErrors = 0;
        for(let assets of dataToValidate) {
            let uniqueElements = new Set();
            let shouldBeUpdated = false;
            const elementsToUpdate = [];
            for(let asset of assets) {
                let inputsWithError = 0;
                let textToValidate = asset.cleaned_text.trim();
                if(textToValidate.length < 1) {
                    countErrors++;
                    shouldBeUpdated = true;
                    errorFound = true;
                    asset = {...asset, error: {status: true, message: [t('component.azatextfield.required')]}}
                    elementsToUpdate.push(asset);
                    continue;
                }

                if(asset.error) {
                    // remove 'error' key from object, in case of recheck (re-init)
                    shouldBeUpdated = true;
                    const {error, ...rest} = asset;
                    asset = rest;
                }

                if(uniqueElements.has(textToValidate.toLowerCase())) {
                    inputsWithError = 1;
                    shouldBeUpdated = true;
                    errorFound = true;
                    const {error, text_type} = asset;
                    const formattedType = text_type.toLowerCase().replace(' ', '-');
                    if(error?.status) {
                        error.message.push(t(`input.error.already-exists.${formattedType}`));
                    } else {
                        asset = {...asset, error: {status: true, message: [t(`input.error.already-exists.${formattedType}`)]}};
                    }
                } else {
                    uniqueElements.add(textToValidate.toLowerCase());
                }

                if(textToValidate.length > maxLength[asset.text_type]) {
                    inputsWithError = 1;
                    shouldBeUpdated = true;
                    errorFound = true;
                    const {error} = asset;
                    if(error?.status) {
                        error.message.push(t('input.error.too-long'));
                    } else {
                        asset = {...asset, error: {status: true, message: [t('input.error.too-long')]}}
                    }
                }

                for(let checker in regExValidation) {
                    if(regExValidation[checker].regex.test(asset.cleaned_text)) {
                        shouldBeUpdated = true;
                        errorFound = true;
                        inputsWithError = 1;
                        const {error} = asset;
                        if(error?.status) {
                            error.message.push(t('google-validation.error.' + regExValidation[checker].message));
                        } else {
                            asset = {...asset, error: {status: true, message: [t('google-validation.error.' + regExValidation[checker].message)]}}
                        }
                    }
                }
                countErrors += inputsWithError;
                elementsToUpdate.push(asset);
            }

            if(shouldBeUpdated) {
                dispatch(actionUpdateAssets(elementsToUpdate));
            }
        }
        return {error: errorFound, counter: countErrors}
    }, [dispatch, maxLength, t]);

    const assetsValidation = useCallback((dataToValidate) => {
        let errorFound = false;
        let countErrors = 0;
        const regExValidation = REGEX_TEXT_CHECKER.google_checker;
        for(let el in dataToValidate) {
            switch(el) {
                case 'assets':
                    const {error, counter} = assetsValidationHelper(dataToValidate[el], regExValidation);
                    errorFound = error;
                    countErrors += counter;
                    //?? if(shouldBeUpdated) {
                    //     dispatch(actionUpdateAssets(elementsToUpdate));
                    // }
                    break;
                case 'brandName':
                    if(dataToValidate[el].length < 1) {
                        errorFound = true;
                        countErrors++;
                        dispatch(actionSetAssetsBrandName({
                            data: '',
                            error: {status: true, message: t('component.azatextfield.required')}
                        }));
                    }
                    break;
                case 'logo':
                    // if(dataToValidate[el].files.length < 1) {
                    //     errorFound = true;
                    //     countErrors++;
                    //     dispatch(actionSetLogo({data: [], error: {status: true, message: t('images.empty')}}));
                    // }
                    break;
                case 'img':
                    // if(dataToValidate[el].files.length < 1) {
                    //     errorFound = true;
                    //     countErrors++;
                    //     dispatch(actionSetImages({data: [], error: {status: true, message: t('images.empty')}}));
                    // }
                    break;
                default:
                    console.log('Something went wrong');
            }
        }
        /*
        set countErrors object to Slice:
        {
            nbrErrors: countErrors,
            step:0
        }
         */
        // this construction is correct just for step "0", when we have two tabs : "Brand" and "Product"
        dispatch(actionSetErrorCounter({[activeStep]: {[assetsScopeObj.scopeName.toLowerCase()]: countErrors}}));
        return errorFound;
    }, [activeStep, assetsScopeObj.scopeName, assetsValidationHelper, dispatch, t]);

    const audienceValidation = useCallback((dataToValidate) => {
        let errorFound = false;
        let countErrors = 0;
        const regExValidation = REGEX_TEXT_CHECKER.keywords_checker;
        for(let el in dataToValidate) {
            switch (el) {
                case 'assets':
                    const {error, counter} = assetsValidationHelper(dataToValidate[el], regExValidation);
                    errorFound = error;
                    countErrors += counter;
                    break;
                case 'country':
                    if(dataToValidate[el].data.length < 1) {
                        countErrors++;
                        errorFound = true;
                        dispatch(actionSetCountries({data: [], error: {status: true, message: t('component.azatextfield.required')}}))
                    }
                    break;
                case 'language':
                    if(dataToValidate[el].data.length < 1) {
                        countErrors++;
                        errorFound = true;
                        dispatch(actionSetLanguages({data: [], error: {status: true, message: t('component.azatextfield.required')}}))
                    }
                    break;
                default:
                    console.log('Something went wrong');
            }
        }

        dispatch(actionSetErrorCounter({[activeStep]: countErrors}));
        return errorFound;
    }, [activeStep, assetsValidationHelper, dispatch, t]);

    const networksValidation = useCallback(() => {
        let countErrors = 0;

        const networksSelected = [networkAzameo.is_selected, networkGoogle.is_selected, networkMicrosoft.is_selected];
        let errorFound = !networksSelected.includes(true);
        if(errorFound) {
            countErrors++;
            dispatch(actionSetIsNetworkSelected(false));
        }
        dispatch(actionSetErrorCounter({[activeStep]: countErrors}));
        return errorFound;
    }, [activeStep, dispatch, networkAzameo.is_selected, networkGoogle.is_selected, networkMicrosoft.is_selected]);

    const campaignSettingsValidation = useCallback(() => {
        let errorFound = false;
        let countErrors = 0;
        if(campaignNameData.new_name.length < 1) {
            errorFound = true;
            countErrors++;
            dispatch(actionSetCampaignName({new_name: '', error: {status: true, message: t('component.azatextfield.required')}}))
        } else {
            for(let el of allCampaignsNames) {
                if(campaignNameData.new_name.toLowerCase() === el.toLowerCase()) {
                    errorFound = true;
                    countErrors++;
                    dispatch(actionSetCampaignName({new_name: campaignNameData.new_name, error: {status: true, message: t('campaigns_data.existing-name')}}))
                    break;
                }
            }
        }

        if(dailyBudgetData.daily_budget < MIN_BUDGET_AMOUNT) {
            errorFound = true;
            countErrors++;
            dispatch(actionSetDailyBudget({daily_budget: dailyBudgetData.daily_budget,
                error: {status: true, message: t('payment.amount.custom.error.minimum_value_to_pay', {
                        val: MIN_BUDGET_AMOUNT,
                        formatParams: {
                            val: {currency: currency ?? "eur",}
                        }
                    })}}))
        }

        if(exceedBudgetChecked) {
            if(exceedBudgetData.exceed_daily_budget < MIN_TARGET_ROI) {
                errorFound = true;
                countErrors++;
                dispatch(actionSetExceedDailyBudget({
                    exceed_daily_budget: exceedBudgetData.exceed_daily_budget,
                    error: {status: true, message: t('min-amount') + MIN_TARGET_ROI + '%'}
                }));
            }
        } else {
            dispatch(actionSetExceedDailyBudget({
                exceed_daily_budget: exceedBudgetData.exceed_daily_budget,
                error: {status: false, message: ''}
            }));
        }
        dispatch(actionSetErrorCounter({[activeStep]: countErrors}));
        return errorFound;
    }, [activeStep,
        allCampaignsNames,
        campaignNameData.new_name,
        currency,
        dailyBudgetData.daily_budget,
        dispatch,
        exceedBudgetChecked,
        exceedBudgetData.exceed_daily_budget,
        t]);

    const validateActiveStep = useCallback((step) => {
        let isError = false;

        switch (step) {
            case 0:
                let addAssetsToValidate;
                if(assetsScopeObj.scopeName === 'Site') {
                    addAssetsToValidate = {
                        assets: [headlinesToValidate, longHeadlinesToValidate, descriptionsToValidate, calloutsToValidate],
                        brandName: brandName.name,
                        logo: logoToValidate,
                        img: imgToValidate,
                    }
                } else {
                    let productAssetsArray = [];
                    for(let el in productAssets) {
                        if(el !== "Keyword") {
                            productAssetsArray.push(productAssets[el])
                        }
                    }

                    addAssetsToValidate = {
                        assets: productAssetsArray,
                        logo: logoToValidate,
                    }
                }
                isError = assetsValidation(addAssetsToValidate, step);
                break;
            case 1:
                let keywordsProductToValidate = [];
                for(let el in productAssets) {
                    if(el === "Keyword") {
                        keywordsProductToValidate.push(productAssets[el])
                    }
                }
                const audienceDataToValidate = {
                    assets: assetsScopeObj.scopeName === 'Site' ? [keywordsToValidate] : keywordsProductToValidate,
                    country: countriesToValidate,
                    language: languageToValidate,
                }
                isError = audienceValidation(audienceDataToValidate);
                break;
            // case 2:
            //     isError = networksValidation();
            //     break;
            case 2:
                isError = campaignSettingsValidation();
                break;
            default:
                console.log(step + ' step passed');
        }

        return !isError;
    }, [
        assetsScopeObj.scopeName,
        assetsValidation,
        audienceValidation,
        brandName.name,
        calloutsToValidate,
        campaignSettingsValidation,
        countriesToValidate,
        descriptionsToValidate,
        headlinesToValidate,
        imgToValidate,
        keywordsToValidate,
        languageToValidate,
        logoToValidate,
        longHeadlinesToValidate,
        productAssets]);

    useEffect(() => {
        if(!siteListLoaded) {
            return;
        }

        if (checkerSiteId !== site_id && isDataLoaded) {
            dispatch(actionSetAssetsWasLoaded(false));
        }

        if (user && site_id && !isOnLoading && !isDataLoaded) {
            resetData();
            setActiveStep(0);
        }
    }, [setActiveStep, user, site_id, isOnLoading, isDataLoaded, siteListLoaded, dispatch, resetData, checkerSiteId]);

    const handleNext = useCallback(() => {
        /* Should we validate here?
        *  or
        *  create a new component
        * */
        if (validateActiveStep(activeStep)) {
            if (activeStep === steps.length - 1) {
                setCreating(true);
                let campaign_params_extended = {...campaignParams};
                const images = allFiles('images');
                const logo = allFiles('logo')
                const image_fields = {};

                if(!!logoDataFromRedux.files.length) {
                    campaign_params_extended['logo'] = logo.map((image, idx) => {
                        const image_name = `logo_${idx}`;
                        image_fields[image_name] = image
                        return image_name
                    })
                }

                if(images?.length > 0 && !!imgToValidate.files) {
                    campaign_params_extended['images'] = images.map((image, idx) => {
                        const image_name = `image_${idx}`;
                        image_fields[image_name] = image
                        return image_name
                    })
                }

                if(assetsScopeObj.scopeName === 'Site') {
                    // remove a key from object and get all that is inside of this key
                    const {site, ...rest} = campaign_params_extended;
                    campaign_params_extended = {...rest, ...site};
                } else {
                    const editedProductParams = campaignParamsEditor(productAssets, 'Product');
                    const {site, ...rest} = campaign_params_extended;
                    campaign_params_extended = {...rest, ...editedProductParams, business_name: productName};
                }

                const data = {
                    params: JSON.stringify(campaign_params_extended),
                    ...image_fields,
                }

                createCampaign(user, site_id, data).then(() => {
                    resetData();
                    navigate(PATH_CAMPAIGNS);
                }).catch((err) => {
                    console.log(err)
                }).finally(()=> {
                    setCreating(false);
                });
            } else {
                // alert('WAS IT CHECKED?');
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setOpenErrorWarning(true);
        }
    }, [
        activeStep,
        allFiles,
        assetsScopeObj.scopeName,
        campaignParams,
        imgToValidate.files,
        logoDataFromRedux.files.length,
        navigate,
        productAssets,
        productName,
        resetData,
        site_id,
        steps.length,
        user,
        validateActiveStep]);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [setActiveStep]);

    /*
        error (errorMsg): create validation function (on click next), useState(true/false)
        error: true/false (?)
        errorMsg: (count error) -> useState(countErr);
     */

    return (
        <AzaBox sx={{width: '100%'}}>
            <AzaPaper variant="outlined" sx={{mb: '30px'}}>
                <AzaStepper
                    activeStep={activeStep}
                    sx={{
                        height: '54px',
                        width: '100%'
                    }}
                >
                    {steps.map((step) => (
                        <AzaStep key={step.label} label={step.label}>
                            <AzaStepLabel>{step.label}</AzaStepLabel>
                        </AzaStep>
                    ))}
                </AzaStepper>
            </AzaPaper>
            <AzaGridContainer>
                <AzaGridItem xs={12} md={9} sx={{mt: 2}}>
                    {steps[activeStep].stepComponent}
                </AzaGridItem>
                <AzaGridItem xs={12} md={3} sx={{position: 'relative'}}>
                    <AzaBox sx={{position: 'sticky', top: '74px'}}>
                        <CompletionBlock
                            title={steps[activeStep].label + ' ' + completion}
                            progress={steps[activeStep].progress}
                            recommendation={steps[activeStep].recommendation}
                            error={activeStep === 0 ? errorsCounter[activeStep][assetsScopeObj.scopeName.toLowerCase()] : errorsCounter[activeStep]}
                            currentStep={activeStep}
                        />
                        {!isAdmin && <AzaBox sx={{pt: 2}}>
                            <AzaAlert severity={"info"}>
                                {t('campaign.creation.commingsoon')}
                            </AzaAlert>
                        </AzaBox>}
                        <AzaBox sx={{display: 'flex', pt: 2}}>
                            <AzaButton
                                sx={{flexGrow: 1}}
                                variant="outlined"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                {t('common.back')}
                            </AzaButton>
                            <AzaBox sx={{ height: '15px', width: '7%' }} />
                            {(isAdmin || activeStep < steps.length - 1) &&
                            <AzaButton sx={{flexGrow: 1}} onClick={handleNext} variant={"contained"} disabled={creating}>
                                {activeStep === steps.length - 1 ? t('create-campaign') : t('common.next')}
                            </AzaButton>}
                            {(!isAdmin && activeStep === steps.length - 1) &&
                            <AzaButton sx={{flexGrow: 1}}  variant={"contained"} disabled>
                                {activeStep === steps.length - 1 ? t('create-campaign') : t('common.next')}
                            </AzaButton>}
                        </AzaBox>
                    </AzaBox>
                </AzaGridItem>
            </AzaGridContainer>
            <AzaDialog
                open={openErrorWarning}
                onClose={handleCloseErrorWarning}
            >
                <AzaDialogTitle onClose={handleCloseErrorWarning}>
                    {t('common.warning')}
                </AzaDialogTitle>
                <AzaDialogContent>
                    <AzaDialogContentText>
                        {t('common.warning-errors')}
                    </AzaDialogContentText>
                </AzaDialogContent>
                <AzaDialogActions>
                    <AzaButton variant={"contained"}  onClick={handleCloseErrorWarning}>Ok</AzaButton>
                </AzaDialogActions>
            </AzaDialog>
        </AzaBox>
    );
}
